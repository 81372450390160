import { ComponentType } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, delayWhen, EMPTY, firstValueFrom, Observable, retryWhen, take, tap, timer } from 'rxjs';
import { CentralServerService } from 'services/central-server.service';
import { MessageService } from 'services/message.service';
import { SpinnerService } from 'services/spinner.service';
import { ChargingStationsAuthorizations, DialogParamsWithAuth } from 'types/Authorization';
import { BillingInvoice } from 'types/Billing';
import { ChargePointStatus, ChargingStation, Connector, OCPPGeneralResponse } from 'types/ChargingStation';
import { ActionResponse, Paging } from 'types/DataResult';
import { FilterParams } from 'types/GlobalType';
import { StartTransaction, StartTransactionDialogData, Transaction } from 'types/Transaction';
import { Utils } from 'utils/Utils';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { User } from 'types/User';
import { ChargingStationsStartTransactionDialogComponent } from 'pages/charging-stations/charging-station-start-transaction/charging-stations-start-transaction-dialog-component';

@Component({
  selector: 'app-charging',
  templateUrl: 'charging.component.html',
  styleUrls: ['charging.component.scss']
})
export class ChargingComponent implements OnInit, OnDestroy {
  tagId: string;
  userId: string;
  transactionId: number | null = null;
  transactionDetails: Transaction;
  formattedDuration: string;
  energyInKWh: number;
  intervalId: any;
  isTransactionStopped = false;
  chargingStationId: string;
  connectorId: number;
  connector: Connector;
  chargingStation: ChargingStation;
  public connectorStatus: string;
  public connectorExists = true;
  invoiceUrl: string;
  public isLoadingInvoice = true;
  transactoinStarted = false;
  user: User;
  isLoading: boolean;
  private maxRetries = 3;
  private token: string;
  private authToken: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private centralServerService: CentralServerService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private dialog: MatDialog,

  ) { }
  async ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });

    const token = this.decodeTokenHeader(this.token);
    const language = localStorage.getItem('language');
    const supportedLanguages = ['en', 'fr', 'es', 'de', 'it', 'pt', 'cs', 'cz'];
    const baseLanguage = language.split('-')[0];
    const languageToUse = supportedLanguages.includes(baseLanguage) ? baseLanguage : 'en';
    this.translateService.use(languageToUse);

    this.authToken = localStorage.getItem('token');
    this.tagId = localStorage.getItem('tagId');
    this.userId = localStorage.getItem('userId');
    const storedTransactionId = localStorage.getItem('transactionId');
    this.transactionId = storedTransactionId ? parseInt(storedTransactionId, 10) : null;
    this.chargingStationId = token.chargingStationID;
    this.connectorId = Number(token.connectorID);

    if (this.transactionId) {
      this.getTransactionDetails();
    }

    this.isLoading = true;
    try {
      await this.loadChargingStationWithRetry();
    } catch (error) {
      console.error('Error loading charging station:', error);
    } finally {
      this.isLoading = false;
    }

    this.intervalId = setInterval(async () => {
      if (this.transactionId) {
        console.log('ChargingComponent ~ this.intervalId=setInterval ~ this.transactionId:', this.transactionId);
        this.isLoading = true;
        try {
          await this.loadChargingStation(this.chargingStationId, this.connectorId);
          this.getTransactionDetails();
        } catch (error) {
          console.error('Error during interval loading:', error);
        } finally {
          this.isLoading = false;
        }
      } else {
        return null;
      }
    }, 10000);
  }


  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  redirectToEv24(): void {
    window.location.href = 'https://www.ev24.io/support/';
  }

  public stopTransaction(): void {
    this.spinnerService.show();
    this.centralServerService.stopTransaction(this.transactionId).subscribe({
      next: (res: ActionResponse) => {
        this.spinnerService.hide();
        if (res.status === OCPPGeneralResponse.ACCEPTED) {
          console.log(`Transaction ${this.transactionId} stopped successfully.`);
          if (this.connectorExists) {
            this.isTransactionStopped = true;
          }
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }
          this.checkConnectorStatus();
        } else {
          console.error('Failed to stop transaction');
        }
      },
      error: (error) => {
        this.spinnerService.hide();
        console.error('Error stopping transaction:', error);
      }
    });
  }

  public ViewInvoice(): void {
    if (this.invoiceUrl) {
      window.open(this.invoiceUrl, '_blank');
    } else {
      console.log('Invoice URl nit found');
    }
  }

  public async getInvoiceByTransactionID(userID: string, transactionID: number): Promise<boolean> {
    return new Promise((resolve) => {
      this.spinnerService.show();
      console.log('Checking for invoice ~ transactionID:', transactionID);
      const paging: Paging = { limit: 1000, skip: 0 };

      this.centralServerService.getInvoices({ userID }, paging).subscribe({
        next: (invoices) => {
          console.log('Received invoices:', invoices);
          if (invoices && invoices.result.length > 0) {
            let foundInvoice: BillingInvoice | undefined;
            for (const invoice of invoices.result) {
              const session = invoice.sessions.find(s => s.transactionID === transactionID);
              if (session) {
                foundInvoice = invoice;
                console.log('Invoice found:', foundInvoice);
                this.sendInvoiceToUser(foundInvoice);
                resolve(true);
                return;
              }
            }
          }
          console.log('No invoice found for this transaction ID.');
          resolve(false);
        },
        error: (error) => {
          console.error('Error fetching invoice:', error);
          resolve(false);
        }
      });
      this.spinnerService.hide();
    });
  }

  public logout() {
    this.centralServerService.logout().subscribe({
      next: () => {
        this.centralServerService.clearLoginInformation();
      },
      error: (error) => {
        this.centralServerService.clearLoginInformation();
      }
    });
  }

  public async getUserTransactions(): Promise<number | null> {
    try {
      const userId = localStorage.getItem('userId');
      const filterParams: FilterParams = { UserID: userId };
      console.log('ChargingComponent ~ getUserTransactions ~ userId:', userId);
      const transactionResult = await firstValueFrom(this.centralServerService.getActiveTransactions(filterParams));
      console.log('ChargingComponent ~ getUserTransactions ~ filterParams:', filterParams);
      if (transactionResult.result.length > 0) {
        console.log('Transaction ID retrieved:', transactionResult.result[0].id);
        return transactionResult.result[0].id;
      } else {
        console.log('No active transactions found for user');
        return null;
      }
    } catch (err) {
      console.error('Error retrieving transactions:', err);
      return null;
    }
  }

  public getTransactionDetails(): void {
    this.centralServerService.getTransaction(this.transactionId).subscribe({
      next: (transaction) => {
        this.transactionDetails = transaction;
        console.log('ChargingComponent ~ this.centralServerService.getTransaction ~ this.transactionDetails:', this.transactionDetails);
        this.formattedDuration = this.convertSecondsToMinutesAndSeconds(transaction.currentTotalDurationSecs);
        this.energyInKWh = this.convertWattsToKWh(transaction.currentTotalConsumptionWh);
      },
      error: (error) => {
        console.error('Error fetching transaction details:', error);
      }
    });
  }

  public async startTransaction(
    chargingStationsStartTransactionDialogComponent: ComponentType<ChargingStationsStartTransactionDialogComponent>,
    translateService: TranslateService,
    dialog: MatDialog,
    chargingStation = this.chargingStation,
    connector = this.connector,
    refresh?: () => Observable<void>
  ): Promise<void> {
    if (!this.connector || !this.connectorExists) {
      console.error('Connector data is not available');
      this.messageService.showErrorMessage(
        this.translateService.instant('chargers.action_error.transaction_start_title'),
        this.translateService.instant('chargers.action_error.connector_not_available')
      );
      return Promise.resolve();
    }
    this.connector = Utils.getConnectorFromID(this.chargingStation, this.connectorId);
    console.log('ChargingComponent ~ this.chargingStation:', this.chargingStation);
    console.log('ChargingComponent ~ connector:', this.connector);
    console.log('ChargingComponent ~ connector status:', this.connector.status);
    if (this.chargingStation.inactive) {
      this.messageService.showErrorMessage(
        translateService.instant('chargers.action_error.transaction_start_title'),
        translateService.instant('chargers.action_error.transaction_start_title')
      );
      return;
    }
    console.log('ChargingComponent ~ connector.status:', connector.status);
    if (connector.status === ChargePointStatus.UNAVAILABLE) {
      this.messageService.showErrorMessage(
        this.translateService.instant('chargers.action_error.transaction_start_title'),
        this.translateService.instant('chargers.action_error.transaction_start_not_available')
      );
      return;
    }
    console.log('ChargingComponent ~ connector.currentTransactionID:', connector.currentTransactionID);
    if (connector.currentTransactionID) {
      this.messageService.showErrorMessage(
        this.translateService.instant('chargers.action_error.transaction_start_title'),
        this.translateService.instant('chargers.action_error.transaction_in_progress')
      );
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '40vw';
    dialogConfig.panelClass = '';

    // Build dialog data
    const dialogData: DialogParamsWithAuth<StartTransactionDialogData, ChargingStationsAuthorizations> = {
      dialogData: {
        id: chargingStation.id,
        chargingStation,
        connector,
      },
    };
    dialogConfig.data = dialogData;

    try {
      const dialogRef = this.dialog.open(chargingStationsStartTransactionDialogComponent, dialogConfig);

      // Await the result from the dialog
      const result = await dialogRef.afterClosed().toPromise();

      if (result) {
        const user = await this.getUserByID(this.userId).toPromise();
        const userFullName = user.fullName;

        this.startTransactionForUser(
          this.chargingStation,
          this.connector,
          userFullName,
          this.userId,
          this.tagId,
          null,
          this.messageService,
          this.translateService,
          this.centralServerService,
          this.router,
          this.spinnerService,
          refresh
        );
      }
    } catch (error) {
      console.log('ChargingComponent ~ error:', error);
    }
  }

  public onStartTransactionButtonClick(): void {
    this.startTransaction(ChargingStationsStartTransactionDialogComponent, this.translateService, this.dialog);
  }

  private getUserByID(userId: string): Observable<User> {
    return this.centralServerService.getUser(userId).pipe(
      tap((user) => {
        this.user = user;
      }),
      catchError((error) => {
        console.error('Error fetching user:', error);
        return EMPTY;
      })
    );
  }

  private async startTransactionForUser(
    chargingStation: ChargingStation,
    connector: Connector,
    userFullName: string,
    userID: string,
    visualTagID: string,
    carID: string | null,
    messageService: MessageService,
    translateService: TranslateService,
    centralServerService: CentralServerService,
    router: Router,
    spinnerService: SpinnerService,
    refresh?: () => Observable<void>
  ): Promise<void> {
    if (!visualTagID) {
      this.messageService.showErrorMessage(
        this.translateService.instant('chargers.start_transaction_missing_active_tag', {
          chargeBoxID: chargingStation.id,
          userName: userFullName,
        })
      );
      return;
    }

    this.spinnerService.show();
    try {
      const startTransactionResponse: ActionResponse = await firstValueFrom(
        this.centralServerService.startTransaction(chargingStation.id, connector.connectorId, userID, visualTagID, carID)
      );

      if (startTransactionResponse.status === OCPPGeneralResponse.ACCEPTED) {
        this.transactoinStarted = true;
        this.messageService.showSuccessMessage(
          this.translateService.instant('chargers.start_transaction_success', { chargeBoxID: chargingStation.id })
        );

        // Set a flag to indicate a pending transaction
        localStorage.setItem('pendingTransaction', 'true');

        // Start checking for the transaction ID
        this.checkForTransactionId(userID);

        if (refresh) {
          await firstValueFrom(refresh());
        }
      } else {
        this.transactoinStarted = false;
        this.messageService.showErrorMessage(
          this.translateService.instant('chargers.start_transaction_error')
        );
      }
    } catch (error) {
      Utils.handleHttpError(error, router, messageService, centralServerService, 'chargers.start_transaction_error');
    } finally {
      this.spinnerService.hide();
    }
  }

  private async loadChargingStationWithRetry(): Promise<void> {
    return firstValueFrom(
      this.centralServerService.getChargingStation(this.chargingStationId).pipe(
        retryWhen(errors =>
          errors.pipe(
            delayWhen(() => timer(1000)),
            take(this.maxRetries)
          )
        )
      )
    ).then(chargingStation => {
      this.chargingStation = chargingStation;
      console.log('ChargingComponent ~ loadChargingStationWithRetry ~ chargingStation:', chargingStation);

      this.connectorExists = chargingStation.connectors.some((connector) => connector.connectorId === this.connectorId);
      this.connector = Utils.getConnectorFromID(chargingStation, this.connectorId);
      console.log('ChargingComponent ~ loadChargingStationWithRetry ~ this.connector:', this.connector);

      if (this.connector) {
        const statusKey = `connectorStatus.${this.connector.status.toUpperCase()}`;
        this.translateService.get(statusKey).subscribe((translatedStatus: string) => {
          this.connectorStatus = translatedStatus || this.connector.status;
        });
      } else {
        this.connectorExists = false;
        this.connectorStatus = '';
      }
    });
  }

  private checkForTransactionId(userId: string): void {
    const checkInterval = setInterval(async () => {
      try {
        const transactionId = await this.getUserTransactions();
        if (transactionId) {
          clearInterval(checkInterval);
          localStorage.setItem('transactionId', transactionId.toString());
          localStorage.removeItem('pendingTransaction');
          this.transactionId = transactionId;
          this.getTransactionDetails();
        }
      } catch (error) {
        console.error('Error checking for transaction ID:', error);
      }
    }, 5000); // Check every 5 seconds

    // Stop checking after 2 minutes (24 * 5 seconds = 2 minutes)
    setTimeout(() => {
      clearInterval(checkInterval);
      if (localStorage.getItem('pendingTransaction')) {
        localStorage.removeItem('pendingTransaction');
        this.messageService.showErrorMessage(
          this.translateService.instant('chargers.start_transaction_timeout')
        );
      }
    }, 120000);
  }

  private async loadChargingStation(entityId: string, connectorId: number): Promise<void> {
    this.isLoading = true;
    return new Promise<void>((resolve, reject) => {
      this.centralServerService.getChargingStation(entityId).subscribe({
        next: (chargingStation: ChargingStation) => {
          this.chargingStation = chargingStation;
          console.log('ChargingComponent ~ this.centralServerService.getChargingStationQr ~ chargingStation:', chargingStation);

          this.connectorExists = chargingStation.connectors.some((connector) => connector.connectorId === connectorId);
          this.connectorId = connectorId;

          this.connector = Utils.getConnectorFromID(chargingStation, connectorId);

          if (this.connector) {
            const statusKey = `connectorStatus.${this.connector.status.toUpperCase()}`;
            this.translateService.get(statusKey).subscribe((translatedStatus: string) => {
              this.connectorStatus = translatedStatus || this.connector.status;
              this.connectorExists = true;
              this.spinnerService.hide();
              resolve();
            });
          } else {
            this.connectorExists = false;
            this.connectorStatus = '';
            this.spinnerService.hide();
            resolve();
          }
        },
        error: (error) => {
          console.error('Failed to load charging station:', error);
          this.spinnerService.hide();
          this.connectorExists = false;
          this.connectorStatus = '';
          reject(error);
        },
      });
    }).finally(() => {
      this.isLoading = false;
    });
  }

  private pollForInvoice(userID: string, transactionID: number): void {
    let retries = 0;
    const maxRetries = 10;
    const pollInterval = 5000;
    this.isLoadingInvoice = true;

    const invoicePolling = setInterval(() => {
      if (retries >= maxRetries) {
        clearInterval(invoicePolling);
        this.isLoadingInvoice = false;
        console.error('Max retries reached. No invoice found.');
        this.spinnerService.hide();
        return;
      }

      this.getInvoiceByTransactionID(userID, transactionID).then((invoiceFound) => {
        if (invoiceFound) {
          clearInterval(invoicePolling);
          this.isLoadingInvoice = false;
          this.spinnerService.hide();
          this.logout();
        }
      });

      retries++;
    }, pollInterval);
  }

  private checkConnectorStatus(): void {
    const checkInterval = setInterval(() => {
      this.loadChargingStation(this.chargingStationId, this.connectorId).then(() => {
        if (this.connector.status === 'Available') {
          clearInterval(checkInterval);
          this.pollForInvoice(this.transactionDetails.user.id, this.transactionDetails.id);
        } else {
          console.log('Connector status is still not available. Checking again...');
        }
      }).catch(error => {
        console.error('Error loading charging station:', error);
      });
    }, 5000);
  }

  private sendInvoiceToUser(invoice: any): void {
    this.spinnerService.show();
    this.centralServerService.getInvoice(invoice.id).subscribe({
      next: (invoiceBlob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const invoiceData = JSON.parse(reader.result as string);
          this.invoiceUrl = invoiceData.downloadUrl;
          const userEmail = this.transactionDetails.user.email;
          console.log(`Simulating sending invoice to ${userEmail} with download link: ${this.invoiceUrl}`);
        };
        reader.onerror = (error) => {
          console.error('Error reading the invoice blob:', error);
        };
        reader.readAsText(invoiceBlob);
      },
      error: (error) => {
        console.error('Error fetching invoice:', error);
      }
    });
    localStorage.removeItem('transactionId');
    localStorage.removeItem('tagId');
    localStorage.removeItem('userId');
    localStorage.removeItem('SessionToken');
    this.spinnerService.hide();
    this.logout();
  }

  private convertSecondsToMinutesAndSeconds(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  }

  private convertWattsToKWh(watts: number): number {
    return watts / 1000;
  }

  private decodeTokenHeader(token: string): any {
    console.log('decodeTokenHeader called with token:', token);

    // Check if the token is non-empty and does not have periods (i.e., no standard JWT format)
    if (!token || token.indexOf('.') === -1) {
      console.log('Token does not contain periods. Treating token as a single part.');

      try {
        // Handle single-part token
        const headerBase64Url = token;

        // Convert base64Url to base64
        const base64UrlToBase64 = (base64Url: string): string => base64Url.replace(/-/g, '+').replace(/_/g, '/');

        // Decode base64 to string
        const decodeBase64Url = (base64Url: string): string => {
          let base64 = base64UrlToBase64(base64Url);
          while (base64.length % 4 !== 0) {
            base64 += '=';
          }
          return atob(base64);
        };

        // Decode and parse the header
        const header = decodeBase64Url(headerBase64Url);
        console.log('Decoded header:', header);
        return JSON.parse(header);
      } catch (error) {
        console.error('Error decoding token header:', error);
        throw new Error('Failed to decode token header');
      }
    } else {
      console.error('Invalid token format. Expected at least one part but got:', token);
      throw new Error('Invalid token format');
    }
  }

}
